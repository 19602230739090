// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Box, Flex, FlexProps, forwardRef, Spacer } from '@chakra-ui/react';
import { FooterBase } from '~/containers/layouts/FooterBase';
import { SocialStack } from '../SocialStack';
import {
  ContactUsLink,
  GuidesLink,
  PricingLink,
  PrivacyLink,
  TermsLink,
} from './FooterContent';

export const Footer = forwardRef<FlexProps, 'div'>((props, ref) => {
  return (
    <FooterBase zIndex={100}>
      <Box
        borderRadius={4}
        border={'1px solid #E9ECEF'}
        bg="white"
        pos={'fixed'}
        right="20px"
        bottom={'70px'}
        zIndex={200}
      >
        <a
          href="https://www.bskyinfo.com/tools/niphtio"
          title="Niphtio - Listed on BskyInfo - The Most Comprehensive Bluesky Tools Directory"
          rel="noopener nofollow"
          aria-label="Niphtio - Listed on BskyInfo - The Most Comprehensive Bluesky Tools Directory"
          data-tool-id="cm6tx6c1f0000pqlil8qmn0s2"
          data-tool-status="listed"
          target="_blank"
        >
          <img
            src="https://www.bskyinfo.com/badges/listed.svg"
            alt="Niphtio - Listed on BskyInfo - The Most Comprehensive Bluesky Tools Directory"
            width="200"
            height="50"
            loading="lazy"
          />
        </a>
      </Box>
      <Flex
        direction={['column', 'column', 'row']}
        fontWeight="medium"
        fontSize="md"
        py={8}
      >
        <Flex gap={[2, 2, 4]} flexDir={['column', 'column', 'row']}>
          <ContactUsLink />
          <GuidesLink />
          <PrivacyLink />
          <TermsLink />
          <PricingLink />
        </Flex>
        <Spacer mb={4} />
        <Flex gap={3} filter="invert(100%)">
          <SocialStack />
        </Flex>
      </Flex>
    </FooterBase>
  );
});
